import React from 'react';

const ButtonComments = ({count, ...props}) => {
  return (
    <a className='c-comments-counter c-comments-counter--s' {...props}>
      <svg
        dangerouslySetInnerHTML={{
          __html: `<use xlink:href='/map.svg#comments'></use>`,
        }}
      />
      {count > 0 && <div className='js-commentscounter'>{count}</div>}
    </a>
  );
};

export default ButtonComments;
