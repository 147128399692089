import React from 'react';
import clsx from 'clsx';
import w60bro from './companies/w60bro';
import SAcarlounge from './companies/SAcarlounge';
import DetailingPoint from './companies/Detailing-Point';

import './css/main.css';
import './css/res.css';
import './css/form.css';
import './css/company.css';

import Reviews from './Reviews';

function Raw({html, alt}) {
  return (
    <div
      className={clsx('c-cp-block', alt && 'c-cp-block--alt')}
      dangerouslySetInnerHTML={{__html: html}}
    />
  );
}

const Company = ({match}) => {
  const companyName = match.params.id;
  const data = {
    w60bro,
    SAcarlounge,
    'Detailing-Point': DetailingPoint,
  }[companyName];

  if (!data) return null;

  return (
    <div className='c-cp'>
      <Raw html={data.cover} />

      <div className='c-cp-body'>
        <Raw alt html={data.info} />
        <Raw html={data.advantages} />
        <Raw html={data.blog} />
        <Raw html={data.photos} />

        <Reviews company={companyName} reviews={data.reviews} />

        <Raw html={data.pricelist} />
        <Raw html={data.about} />
        <Raw html={data.contacts} />
      </div>
    </div>
  );
};

export default Company;
