import React, {useState} from 'react';
import Filters from './Filters';

// const filtersKeys = ['district', 'nowOpen', 'hasFeedbacks', 'hasPosts'];

// function isTruthy(value) {
//   if (!value) return false;
//   if ('object' !== typeof value) return false;
//   if (!value.length || !Object.values(value).length) return false;
//   return true;
// }

// function hasInitialFilters(filters) {
//   for (const [key, value] of Object.entries(filters)) {
//     if (!filtersKeys.includes(key)) continue;
//     if (isTruthy(value)) return true;
//   }
//   return false;
// }

function Form({activeFilters, onSubmit, onChangeFilters, companies} = {}) {
  const {category, brand} = activeFilters;
  const disabled = !category && !brand;

  const [filtersShown, setFiltersShown] = useState(true);

  return (
    <>
      <form className='c-form' onSubmit={onSubmit}>
        <div className='c-form__block c-companies-form'>
          <div className='c-form__item'>
            <div className='c-form__body'>
              <select name='location'>
                <option value='295'>Москва и область</option>
              </select>
            </div>
          </div>
          <div className='c-form__item'>
            <div className='c-form__body'>
              <select id='category' name='category' value={category} onChange={onChangeFilters}>
                <option value=''>С чем помочь</option>
                <option>Ремонт</option>
                <option>ТО</option>
                <option>Детейлинг</option>
                <option>Тюнинг</option>
                <option>Запчасти и аксессуары</option>
                <option value='Other'>Другое</option>
              </select>
            </div>
          </div>
          <div className='c-form__item'>
            <div className='c-form__body'>
              <select id='brand' name='brand' value={brand} onChange={onChangeFilters}>
                <option value=''>Марка машины</option>
                <option value='AAAAAAAAAAE'>Acura</option>
                <option value='AAAAAAAAABE'>Alfa Romeo</option>
                <option value='AAAAAAAAAAI'>Audi</option>
                <option value='AAAAAAAAAAM'>BMW</option>
                <option value='AAAAAAAAACQ'>Cadillac</option>
                <option value='AAAAAAAAACU'>Chery</option>
                <option value='AAAAAAAAABQ'>Chevrolet</option>
                <option value='AAAAAAAAABU'>Chrysler</option>
                <option value='AAAAAAAAAAQ'>Citroen</option>
                <option value='AAAAAAAAABY'>Dodge</option>
                <option value='AAAAAAAAAEg'>Ferrari</option>
                <option value='AAAAAAAAABc'>FIAT</option>
                <option value='AAAAAAAAACg'>Ford</option>
                <option value='AAAAAAAAAEw'>Geely</option>
                <option value='AAAAAAAAACs'>Honda</option>
                <option value='AAAAAAAAAAU'>Hummer</option>
                <option value='AAAAAAAAAAY'>Hyundai</option>
                <option value='AAAAAAAAAAc'>Infiniti</option>
                <option value='AAAAAAAAAC0'>Jaguar</option>
                <option value='AAAAAAAAABg'>Jeep</option>
                <option value='AAAAAAAAABk'>KIA</option>
                <option value='AAAAAAAAAEk'>Lamborghini</option>
                <option value='AAAAAAAAABo'>Lancia</option>
                <option value='AAAAAAAAABs'>Land Rover</option>
                <option value='AAAAAAAAAAk'>Lexus</option>
                <option value='AAAAAAAAAE8'>Maserati</option>
                <option value='AAAAAAAAAB0'>Mazda</option>
                <option value='AAAAAAAAAC8'>Mercedes-Benz</option>
                <option value='AAAAAAAAADI'>MINI</option>
                <option value='AAAAAAAAADM'>Mitsubishi</option>
                <option value='AAAAAAAAADQ'>Nissan</option>
                <option value='AAAAAAAAADY'>Opel</option>
                <option value='AAAAAAAAAAs'>Peugeot</option>
                <option value='AAAAAAAAADg'>Pontiac</option>
                <option value='AAAAAAAAADk'>Porsche</option>
                <option value='AAAAAAAAADo'>Renault</option>
                <option value='AAAAAAAAAB4'>Saab</option>
                <option value='AAAAAAAAAA0'>Scion</option>
                <option value='AAAAAAAAAA4'>SEAT</option>
                <option value='AAAAAAAAAA8'>Skoda</option>
                <option value='AAAAAAAAACE'>Smart</option>
                <option value='AAAAAAAAAD4'>Subaru</option>
                <option value='AAAAAAAAAD8'>Suzuki</option>
                <option value='AAAAAAAAAHk'>Tesla</option>
                <option value='AAAAAAAAAEA'>Toyota</option>
                <option value='AAAAAAAAAEE'>Volkswagen</option>
                <option value='AAAAAAAAABA'>Volvo</option>
                <option value='AAAAAAAAAEM'>ГАЗ</option>
                <option value='AAAAAAAAAEQ'>ЗАЗ</option>
                <option value='AAAAAAAAAEI'>Лада</option>
                <option value='AAAAAAAAAFM'>ЛуАЗ</option>
                <option value='AAAAAAAAAEc'>УАЗ</option>
              </select>
            </div>
          </div>

          <Filters
            active={filtersShown}
            filters={activeFilters}
            onActivate={() => setFiltersShown(true)}
            onChangeFilters={onChangeFilters}
            companies={companies}
          />

          <div className='c-form__item c-form__item--submit'>
            <button
              className='c-button c-button--solid c-button--l u-mobile-100'
              type='submit'
              disabled={disabled}>
              Посмотреть
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Form;
