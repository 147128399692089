import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import queryString from 'query-string';

import data from './data/compiled.json';
import {directBrandsMatcher} from './data/matchers';

import ScrollToTop from './ScrollToTop';
import Company from './Company';
import Factoid from './Factoid';
import Header from './Header';
import Promo from './Promo';
import Form from './Form';
import Item from './Item';

import './css/main.css';
import './css/res.css';
import './css/form.css';
import './css/companies.css';
import './App.css';

const history = createBrowserHistory();

function makeParamArrayish(paramsQuery, param) {
  const regex = new RegExp(param, 'gi');
  return paramsQuery.replace(regex, param + '[]');
}

function componseUrl(e) {
  if (!window.FormData || !window.URLSearchParams || !window.history) return;

  const formData = new FormData(e.target);
  const searchParams = new URLSearchParams(formData).toString();
  const normalizedParams = makeParamArrayish(searchParams, 'district');
  const url = window.location.pathname + '?' + normalizedParams;
  history.push(url);
}

function parseUrl() {
  return queryString.parse(window.location.search, {
    parseBooleans: true,
    arrayFormat: 'bracket',
  });
}

const defaultFilterState = {
  district: [],
  nowOpen: false,
  hasFeedbacks: false,
  hasPosts: false,
  direction: '',
  category: '',
  brand: '',
};

function getDefaultFilters() {
  const searchParams = parseUrl();
  return {
    ...defaultFilterState,
    ...searchParams,
  };
}

function hasInitialFilters() {
  const searchParams = parseUrl();
  return Object.keys(searchParams).length > 0;
}

function App() {
  const [open, setOpen] = useState(hasInitialFilters());
  const [activeFilters, setActiveFilters] = useState({...getDefaultFilters()});
  const [listFilters, setListFilters] = useState({...getDefaultFilters()});
  const [showAdditional, setShowAdditional] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    setShowAdditional(false);
    setListFilters(activeFilters);
    componseUrl(e);
  };

  const changeFilters = (e) => {
    const {value, name, checked} = e.target;

    if (name === 'district') {
      return setActiveFilters({
        ...activeFilters,
        district: activeFilters.district.includes(value)
          ? activeFilters.district.filter((d) => d !== value)
          : [...activeFilters.district, value],
      });
    }

    setActiveFilters({
      ...activeFilters,
      [name]: name === 'category' || name === 'brand' ? value : checked,
    });
  };

  return (
    <div className='body'>
      <Header />
      <Router>
        <>
          <ScrollToTop />
          <Switch>
            <Route
              exact
              path='/'
              render={() => (
                <main>
                  <div className='l-top' />
                  <div className='l-container'>
                    <div className='l-page-header'>
                      <h1 className='c-header c-header-floating'>Компании</h1>
                    </div>

                    <Promo />

                    <Form
                      onSubmit={handleSubmit}
                      onChangeFilters={changeFilters}
                      activeFilters={activeFilters}
                      companies={data}
                    />

                    <div className='c-companies-cols' hidden={!open}>
                      <List
                        data={data}
                        filters={listFilters}
                        showAdditional={showAdditional}
                        setShowAdditional={setShowAdditional}
                      />
                    </div>
                  </div>
                </main>
              )}
            />
            <Route path='/o/:id' component={Company} />
          </Switch>
        </>
      </Router>
    </div>
  );
}

function isCompanyOpen(company) {
  return company.workingHours.toLowerCase().includes('открыто');
}

function prepareData(data, filters) {
  const {category, brand, hasPosts, hasFeedbacks, nowOpen} = filters;
  return data
    .filter((company) => !category || company.generalCategories.includes(category))
    .filter((company) => !brand || company.brands.includes(brand))
    .filter((company) => !hasFeedbacks || company.reviews > 0)
    .filter((company) => !hasPosts || company.blog > 0)
    .filter((company) => !nowOpen || isCompanyOpen(company));
}

function splitByLocation(data, filters) {
  const {district} = filters;

  const filterByDistrict = (company) => {
    if (!district.length) return 1;

    for (const cd of company.districts) {
      if (district.includes(cd)) return 1;
    }

    return 0;
  };

  const includesDistrict = (company) => filterByDistrict(company) > 0;
  const excludesDistrict = (company) => filterByDistrict(company) <= 0;
  return [data.filter(includesDistrict), data.filter(excludesDistrict)];
}

function sortByReviews(companies) {
  return companies.sort((a, b) => (a.reviews < b.reviews ? 1 : -1));
}

/**
 * ! Warning
 * ! Filters out every company except 3 selected for study
 */
function __dangerouslyFilterCompanies(data, filters) {
  const selectedCompanies = ['SAcarlounge', 'w60bro', 'Detailing-Point'];
  const selectedBrand = 'AAAAAAAAAAM';
  const selectedCtegory = 'Детейлинг';

  const {brand, category} = filters;
  if (brand !== selectedBrand || category !== selectedCtegory) return data;
  return data.filter((company) => selectedCompanies.includes(company.nickname));
}

function List({data, filters, showAdditional, setShowAdditional}) {
  data = __dangerouslyFilterCompanies(data, filters);

  const {brand} = filters;
  const companies = prepareData(data, filters);
  if (companies.length <= 0)
    return <div style={{margin: '20px 0', textAlign: 'center'}}>Таких компаний нет</div>;

  const [includes, excldues] = splitByLocation(companies, filters);
  const found = includes.length > 0;
  const hasAlternatives = excldues.length > 0;

  return (
    <>
      {found && <Factoid>Есть куда поехать!</Factoid>}
      {sortByReviews(includes).map((company) => (
        <Item key={company.url} company={company} selectedBrand={directBrandsMatcher[brand]} />
      ))}

      {!found ? (
        <Factoid>Таких компаний нет, но мы нашли несколько компаний чуть подальше</Factoid>
      ) : (
        hasAlternatives && <Factoid>И ещё несколько компаний чуть подальше...</Factoid>
      )}

      {hasAlternatives &&
        (showAdditional ? (
          sortByReviews(excldues).map((company) => (
            <Item key={company.url} company={company} selectedBrand={directBrandsMatcher[brand]} />
          ))
        ) : (
          <div className='additional-list-controls'>
            <button type='button' className='c-button' onClick={() => setShowAdditional(true)}>
              Показать...
            </button>
          </div>
        ))}
    </>
  );
}

export default App;
