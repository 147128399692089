import React from 'react';
import './css/special-offer.css';

const SpeicalOffer = ({offer}) => {
  return (
    <article className='special-offer'>
      <h3>{offer}</h3>
      <footer>Спецусловия для DRIVE2.RU</footer>
    </article>
  );
};

export default SpeicalOffer;
