import React from 'react';
import clsx from 'clsx';
import ButtonLike from './ButtonLike';
import ButtonComments from './ButtonComments';
import './css/review.css';

const ReviewRating = ({company, satisfied = false}) => (
  <div className='c-com-feedback-card'>
    <div className='c-com-feedback-card__header'>
      <div
        className={clsx(
          'c-com-feedback-card__kind c-rating c-rating--wrap',
          !satisfied && 'c-rating--negative'
        )}>
        {satisfied ? 'Доволен' : 'Недоволен'} компанией {company.name}
        {' '}
        <svg
          dangerouslySetInnerHTML={{
            __html: satisfied
              ? `<use xlink:href="/map.svg#thumb-up"></use>`
              : `<use xlink:href="/map.svg#thumb-down"></use>`,
          }}
        />
      </div>
    </div>
    <div className='c-com-feedback-card__verified c-com-feedback-card__verified--alt'>
      Проверенный отзыв
    </div>
  </div>
);

const Review = ({review}) => {
  const {company, author} = review;

  return (
    <div className='c-block c-block--base js-entity'>
      <div className='c-block-card'>
        <div className='o-spacing-m c-block-card__meta'>
          <div className='c-author'>
            <div className='c-author__avatar'>
              <a href={review.url}>
                <div className='o-img is-rounded u-vignette-tiny is-loaded'>
                  <span
                    style={{
                      display: 'block',
                      backgroundColor: '#f8f8f8',
                      paddingTop: '70%',
                      width: '60px',
                    }}></span>
                  <img src={author.image} alt={author.name} width='120' />
                </div>
              </a>
            </div>
            <div className='c-author__body'>
              <div className='c-author__header'>
                <a className='c-car-title c-link c-link--text' href={review.url}>
                  {author.car}
                </a>
              </div>
              <div className='c-author__date'>{review.ago}</div>
            </div>
          </div>
        </div>

        <div className='c-block-card__body'>
          <div className='js-entity-body'>
            <a className='u-link-area' href={review.url} rel='noopener noreferrer' target='_blank'>
              <span className='u-visually-hidden'>{review.title}</span>
            </a>
            <div className='c-post-preview js-postpreview'>
              <div className='c-preview-pics'>
                <div className='c-preview-pic'>
                  <a href={review.url} rel='noopener noreferrer' target='_blank'>
                    <div className='o-img is-rounded u-vignette is-loaded'>
                      <span style={{display: 'block', paddingTop: '56%'}}></span>
                      <img src={review.leadPhoto} width='480' height='360' alt={review.title} />
                    </div>
                  </a>

                  {review.photosCount > 1 && (
                    <span className='c-preview-pic__angle-counter'>
                      <span>+{review.photosCount - 1} фото</span>
                    </span>
                  )}
                </div>
              </div>
            </div>

            {review.title && (
              <div className='c-post-preview__title'>
                <a
                  className='c-link c-link--text'
                  href={review.url}
                  rel='noopener noreferrer'
                  target='_blank'>
                  {review.title}
                </a>
              </div>
            )}

            <div className='c-post-preview__lead'>{review.text}</div>

            <ReviewRating satisfied={review.isSatisfied} company={company} />

            {company.answer && (
              <div className='c-comments-preview'>
                <div className='c-comments-preview__body'>
                  <div className='c-comments-preview__item'>
                    <div className='c-comments-preview__avatar'>
                      <div className='o-img is-round is-loaded'>
                        <span style={{display: 'block', paddingTop: '100%'}}></span>
                        <img src={company.avatar} width='60' height='60' alt='asas' />
                      </div>
                    </div>
                    <div className='c-comments-preview__comment'>{company.answer}</div>
                  </div>
                </div>
              </div>
            )}

            <div className='c-post-controls'>
              <ButtonLike count={review.likes} />
              <ButtonComments count={review.comments} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
