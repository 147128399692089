import React from 'react';

export default function({active = true, filters, onActivate, onChangeFilters, companies}) {
  const {district, nowOpen, hasFeedbacks, hasPosts} = filters;

  return (
    <>
      <div className='c-form__item c-form__hspacing c-companies-form__filters-group'>
        {!active ? (
          <button className='c-button c-button--l u-mobile-100' onClick={onActivate} type='button'>
            Районы и фильтры
          </button>
        ) : (
          <div data-slot='companies-form.filters'>
            <div className='c-companies-form__filters'>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='tsao'
                  onChange={onChangeFilters}
                  checked={district.includes('tsao')}
                />
                <span className='c-toggle__label'>ЦАО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='sao'
                  onChange={onChangeFilters}
                  checked={district.includes('sao')}
                />
                <span className='c-toggle__label'>САО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='szao'
                  onChange={onChangeFilters}
                  checked={district.includes('szao')}
                />
                <span className='c-toggle__label'>СЗАО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='zao'
                  onChange={onChangeFilters}
                  checked={district.includes('zao')}
                />
                <span className='c-toggle__label'>ЗАО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='yuzao'
                  onChange={onChangeFilters}
                  checked={district.includes('yuzao')}
                />
                <span className='c-toggle__label'>ЮЗАО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='yuao'
                  onChange={onChangeFilters}
                  checked={district.includes('yuao')}
                />
                <span className='c-toggle__label'>ЮАО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='yuvao'
                  onChange={onChangeFilters}
                  checked={district.includes('yuvao')}
                />
                <span className='c-toggle__label'>ЮВАО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='vao'
                  onChange={onChangeFilters}
                  checked={district.includes('vao')}
                />
                <span className='c-toggle__label'>ВАО</span>
              </label>
              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='district'
                  value='svao'
                  onChange={onChangeFilters}
                  checked={district.includes('svao')}
                />
                <span className='c-toggle__label'>СВАО</span>
              </label>

              <hr className='c-companies-form__separator' />

              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='nowOpen'
                  value='true'
                  checked={nowOpen}
                  onChange={onChangeFilters}
                />
                <span className='c-toggle__label'>Открыто сейчас</span>
              </label>

              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='hasFeedbacks'
                  value='true'
                  checked={hasFeedbacks}
                  onChange={onChangeFilters}
                />
                <span className='c-toggle__label'>С отзывами</span>
              </label>

              <label className='c-toggle c-companies-form__filter'>
                <input
                  type='checkbox'
                  name='hasPosts'
                  value='true'
                  checked={hasPosts}
                  onChange={onChangeFilters}
                />
                <span className='c-toggle__label'>С блогом</span>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
