import React from 'react';

const ButtonLike = ({count = 0}) => {
  return (
    <button className='r-button-unstyled c-like  c-like--s'>
      <div className='c-like__button'>
        <svg
          dangerouslySetInnerHTML={{
            __html: `<use xlink:href="/map.svg#like"></use>`,
          }}
        />
      </div>
      {count > 0 && <div className='c-like__counter'>{count}</div>}
    </button>
  );
};

export default ButtonLike;
