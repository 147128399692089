import React from 'react';

function Header() {
  return (
    <header className='l-header'>
      <div className='c-top'>
        <div className='c-top__container' data-role='topnav'>
          <a className='c-logo js-top-link' rel='home' href='/'>
            <span>DRIVE2.RU</span>
          </a>

          <div className='c-top-nav'>
            <a
              className='c-top-nav__link '
              href='/search/'
              data-slot='topnav.link'
              data-ym-target='menu_search'>
              <svg
                className='c-top-nav__icon'
                dangerouslySetInnerHTML={{
                  __html: `<use xlink:href='/map.svg#search' />`,
                }}
              />
            </a>
          </div>
          <div className='c-top-nav'>
            <button
              className='r-button-unstyled c-top-nav__link'
              data-slot='topnav.link topnav.subnav'>
              <svg
                className='c-top-nav__icon c-top-nav__icon--wide c-hamburger'
                dangerouslySetInnerHTML={{
                  __html: `<use xlink:href='/map.svg#menu' />`,
                }}
              />
              <svg
                className='c-top-nav__icon c-top-nav__icon--wide c-hamburger-close'
                dangerouslySetInnerHTML={{
                  __html: `<use xlink:href='/map.svg#menu-close' />`,
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
