function swap(obj) {
  let ret = {};
  for (var key in obj) {
    ret[obj[key]] = key;
  }
  return ret;
}

const districtsMatcher = {
  Центр: 'tsao',
  Север: 'sao',
  'Северо-запад': 'szao',
  Запад: 'zao',
  'Юго-запад': 'yuzao',
  Юг: 'yuao',
  'Юго-восток': 'yuvao',
  Восток: 'vao',
  'Северо-восток': 'svao',
};

const directDistrictMatcher = {
  tsao: 'ЦАО',
  sao: 'САО',
  szao: 'СЗАО',
  zao: 'ЗАО',
  yuzao: 'ЮЗАО',
  yuao: 'ЮАО',
  yuvao: 'ЮВАО',
  vao: 'ВАО',
  svao: 'СВАО',
};

const directionsMatcher = {
  'Автозвук и шумоизоляция': 'BzLcgEAABKI',
  'Автостёкла и оптика': 'BzLcgEAABFY',
  'Агрегатный тюнинг': 'BzLcgEAABTI',
  'Аксессуары, инструменты, химия': 'BzLcwEAAACg',
  'Внешний тюнинг и стайлинг': 'BzLcgEAABKM',
  'Детейлинг, полировка и уход': 'BzLcgEAABFw',
  Диагностика: 'BzLcgEAAA2k',
  'Диски и шины': 'BzLcwEAAACM',
  'Кондиционер и климат': 'BzLcgEAAA3A',
  'Кузовной ремонт и покраска': 'BzLcgEAAA9k',
  'Официальный дилер': 'BzLcwEAAACs',
  'Плановое ТО': 'BzLcgEAAAvQ',
  'Продажа новых и б/у запчастей': 'BzLcwEAAACE',
  'Слесарные работы': 'BzLcgEAAA2M',
  'Сложный ремонт двигателя': 'BzLcgEAAA28',
  'Тюнинг интерьера': 'BzLcgEAABKg',
  'Чип-тюнинг': 'BzLcgEAABF8',
  Электрооборудование: 'BzLcgEAAA9w',
};

const directDirectionsMatcher = swap(directionsMatcher);

const brandsMatcher = {
  Acura: 'AAAAAAAAAAE',
  'Alfa Romeo': 'AAAAAAAAABE',
  Audi: 'AAAAAAAAAAI',
  BMW: 'AAAAAAAAAAM',
  Cadillac: 'AAAAAAAAACQ',
  Chery: 'AAAAAAAAACU',
  Chevrolet: 'AAAAAAAAABQ',
  Chrysler: 'AAAAAAAAABU',
  Citroen: 'AAAAAAAAAAQ',
  Dodge: 'AAAAAAAAABY',
  Ferrari: 'AAAAAAAAAEg',
  FIAT: 'AAAAAAAAABc',
  Ford: 'AAAAAAAAACg',
  Geely: 'AAAAAAAAAEw',
  Honda: 'AAAAAAAAACs',
  Hummer: 'AAAAAAAAAAU',
  Hyundai: 'AAAAAAAAAAY',
  Infiniti: 'AAAAAAAAAAc',
  Jaguar: 'AAAAAAAAAC0',
  Jeep: 'AAAAAAAAABg',
  KIA: 'AAAAAAAAABk',
  Lamborghini: 'AAAAAAAAAEk',
  Lancia: 'AAAAAAAAABo',
  'Land Rover': 'AAAAAAAAABs',
  Lexus: 'AAAAAAAAAAk',
  Maserati: 'AAAAAAAAAE8',
  Mazda: 'AAAAAAAAAB0',
  'Mercedes-Benz': 'AAAAAAAAAC8',
  MINI: 'AAAAAAAAADI',
  Mitsubishi: 'AAAAAAAAADM',
  Nissan: 'AAAAAAAAADQ',
  Opel: 'AAAAAAAAADY',
  Peugeot: 'AAAAAAAAAAs',
  Pontiac: 'AAAAAAAAADg',
  Porsche: 'AAAAAAAAADk',
  Renault: 'AAAAAAAAADo',
  Saab: 'AAAAAAAAAB4',
  Scion: 'AAAAAAAAAA0',
  SEAT: 'AAAAAAAAAA4',
  Skoda: 'AAAAAAAAAA8',
  Smart: 'AAAAAAAAACE',
  Subaru: 'AAAAAAAAAD4',
  Suzuki: 'AAAAAAAAAD8',
  Tesla: 'AAAAAAAAAHk',
  Toyota: 'AAAAAAAAAEA',
  Volkswagen: 'AAAAAAAAAEE',
  Volvo: 'AAAAAAAAABA',
  ГАЗ: 'AAAAAAAAAEM',
  ЗАЗ: 'AAAAAAAAAEQ',
  Лада: 'AAAAAAAAAEI',
  ЛуАЗ: 'AAAAAAAAAFM',
  УАЗ: 'AAAAAAAAAEc',
};

const directBrandsMatcher = swap(brandsMatcher);

module.exports = {
  districtsMatcher,
  directionsMatcher,
  brandsMatcher,

  directDistrictMatcher,
  directDirectionsMatcher,
  directBrandsMatcher,
};
