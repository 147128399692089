import React from 'react';
import Review from './Review';

const Reviews = ({company, reviews}) => {
  const url = `https://drive2.ru/o/${company}/reviews`;
  return (
    <div className='c-cp-block'>
      <span className='o-anchor-l' id='reviews'></span>
      <div className='c-cp-block__wrapper c-cp-feedback-container'>
        <h3 className='c-cp-header'>Отзывы</h3>
        <h4 className='c-cp-sub-header c-link-decorated'>
          <a href={url} className='c-link'>
            20 отзывов
          </a>{' '}
          проверено DRIVE2
        </h4>

        {reviews.map((review) => (
          <Review key={review.url} review={review} />
        ))}
      </div>
    </div>
  );
};

export default Reviews;
