import React from 'react';
import './css/promo.css';

const Promo = () => {
  return (
    <div className='c-block promo'>
      <p>
        Сервисы, тюнинг и магазины со всего DRIVE2. С отзывами из бортжурналов, спецусловиями
        и блогом. Встречайте!
      </p>
      <a href='https://drive2.ru/' className='c-block__more'>
        Узнать подробнее
      </a>
    </div>
  );
};

export default Promo;
