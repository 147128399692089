import React from 'react';
import {Link} from 'react-router-dom';
import SpecialOffer from './SpecialOffer';
import {directDistrictMatcher} from './data/matchers';
import './css/item.css';

const pluralize = (case1, case2, case3) => (nmbr) => {
  const base = nmbr - Math.floor(nmbr / 100) * 100;
  if (base > 9 && base < 20) return case3;
  else {
    const remainder = nmbr - Math.floor(nmbr / 10) * 10;

    if (1 === remainder) return case1;
    else if (0 < remainder && 5 > remainder) return case2;
    else return case3;
  }
};

const pluralizeBrand = pluralize('марку', 'марки', 'марок');
const pluralizeService = pluralize('услуга', 'услуги', 'услуг');

export default function({company, selectedBrand, selectedService}) {
  const {
    title,
    url,
    image,
    subtitle,
    address,
    workingHours,
    reviews,
    blog,
    brands,
    directionsCount,
    isMoscow,
    location = '',
    districts = [],
    bonus = null,
  } = company;

  const isOpen = workingHours.toLowerCase().includes('открыто');
  const hasBrands = brands.length > 0;
  const hasServices = directionsCount > 0;

  const path = url.replace('https://drive2.ru', '');

  return (
    <Link className='c-block c-companies__item' to={path}>
      <div>
        <div className='c-companies__avatar'>
          <div className='o-img is-round u-vignette is-loaded' data-size='100,100' data-defined=''>
            <span style={{display: 'block', paddingTop: '100%'}} />
            <img src={image} alt={title} width='100' height='100' />
          </div>
        </div>
        <div className='c-companies__body'>
          <h3 className='c-companies__title'>{title}</h3>
          <div className='c-companies__description u-break-word'>{subtitle}</div>

          {hasBrands && (
            <>
              {!selectedBrand ? (
                <div className='c-companies__brands u-break-word'>
                  Обслуживают {brands.length} {pluralizeBrand(brands.length)}
                </div>
              ) : (
                <div className='c-companies__brands u-break-word'>
                  Обслуживают <b>{selectedBrand}</b> и ещё {brands.length - 1}
                  {' '}
                  {pluralizeBrand(brands.length - 1)}
                </div>
              )}
            </>
          )}

          {hasServices && (
            <>
              {!selectedService ? (
                <div className='c-companies__service u-break-word'>
                  Есть {directionsCount} {pluralizeService(directionsCount)}
                </div>
              ) : (
                <div className='c-companies__service u-break-word'>
                  Есть <b>{selectedService}</b> и ещё {directionsCount - 1}
                  {' '}
                  {pluralizeService(directionsCount - 1)}
                </div>
              )}
            </>
          )}

          <div className='c-companies__address u-break-word'>
            {!isMoscow ? (
              <span>{location.substr(0, location.indexOf(','))}</span>
            ) : (
              <>{districts.map((d) => directDistrictMatcher[d]).join(', ')}</>
            )}
            {', '}
            {isMoscow ? address : address.substr(address.indexOf(',') + 2)}
          </div>

          <div className={`c-companies__working-hours ${isOpen ? 'is-open' : 'is-closed'}`}>
            {workingHours}
          </div>

          {!!bonus && <SpecialOffer offer={bonus} />}

          <div className='o-group o-group--xl c-companies__stats'>
            <div>
              <span>Отзывы</span> {reviews}
            </div>
            <div>
              <span>Блог</span> {blog}
            </div>

            <button className='r-button-unstyled c-bookmarks-button c-bookmarks-button--s'>
              <svg
                dangerouslySetInnerHTML={{
                  __html: `<use xlink:href='/map.svg#bookmark'></use>`,
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
}
